<template>
    <div>
      <v-container class="d-flex justify-space-between align-center pb-0">
        <page-title title="請款列表" class="d-inline" />
        <v-btn
          depressed
          @click="openModal"
          class="d-inline float-right"
          color="#009775"
          style="color: white"
        >
          新增請款
        </v-btn>
      </v-container>
  
      <v-container class="py-0 pr-0 mb-2 d-flex">
        <v-container class="py-0 my-4">
          <liff-list-search style="width: 100%;" class="" :listKey="listKey" />
        </v-container>
        <liff-list-filter style="width: 100%;" :listKey="listKey" />
      </v-container>
  
      <v-container class="py-0 mb-2 d-flex justify-end">
        <v-select
          class="apply-pay-pager"
          style="max-width: 165px"
          outlined
          dense
          :items="perPageOptions"
          v-model="perPage"
        ></v-select>
      </v-container>
      <!--        <liff-list :listKey="listKey"></liff-list>-->
      <v-container class="py-0 mb-4">
        <list-item
          v-for="(data, index) in listData"
          :key="index"
          :listKey="listKey"
          :data="data"
        >
        </list-item>
      </v-container>
      <v-pagination
        v-if="createPager"
        v-model="page"
        :length="pager.pages"
        :total-visible="6"
        elevation="0"
        class="mb-8"
      ></v-pagination>
      <div v-if="createPager" style="height: 60px"></div>
      <applyPayModal
        v-if="showModal"
        title="請選擇請款店家"
        @close="onCloseModal"
        @apply="onApplyModal"
      />
    </div>
  </template>
  
  <script lang="babel" type="text/babel">
  import listMixin from "@/components/list/mixins/listMixin.js";
  import listConfig from "./applyPayOverview";
  import liffListSearch from "components/liffList/listSearch/liffListSearch.vue";
  import liffListFilter from "components/liffList/listFilter/liffListFilter.vue";
  import dayjs from "dayjs";

  export default {
    mixins: [listMixin],
    components: {
      liffListFilter,
      liffListSearch,
      applyPayModal: () =>
        import("@/modules/provider/components/modal/applyPayModal.vue"),
      listItem: () =>
        import("@/modules/provider/views/applyPay/item/applyPayItem.vue"),
    },
    data: () => ({
      listKey: "liff-provider-apply-pay-overview",
      showModal: false,
      meta: {},
      params: {
        page: 1,
        per: 1,
      },
    }),
    computed: {
      createPager() {
        if (!this.pager) return false;
        return this.pager.pages > 1;
      },
      providerId() {
        return this.$store.getters[`member/providerId`];
      },
      listData() {
        return this.$store.getters[`list/${this.listKey}/data`];
      },
      pager() {
        return this.$store.getters[`list/${this.listKey}/pager`];
      },
      page: {
        get() {
          return this.$store.getters[`list/${this.listKey}/page`];
        },
        set(value) {
          this.$store.dispatch(`list/${this.listKey}/set`, {
            key: "page",
            value,
          });
  
          this.listActions.changeIndexCondition(true);
        },
      },
      perPageOptions() {
        return this.$store.getters[`list/${this.listKey}/perPageOptions`];
      },
      perPage: {
        get() {
          return this.$store.getters[`list/${this.listKey}/perPage`];
        },
        set(value) {
          this.$store.dispatch(`list/${this.listKey}/set`, {
            key: "perPage",
            value,
          });
  
          // 更換每頁筆數後從第一頁開始
          this.$store.dispatch(`list/${this.listKey}/set`, {
            key: "page",
            value: 1,
          });
  
          this.listActions.changeIndexCondition();
        },
      },
    },
    mounted() {
      this.$root.gaLogEvent("供應商_請款頁");
    },
    methods: {
      async beforeIndex() {
        await Promise.all([]);
      },
      async indexApi(params) {
        const res = await this.$api.collection.applyPayApi.overview({
          provider: this.providerId,
          params,
        });
  
        res.data = res.data.map((item) => ({
          apply_pay_id: item.applyPay_id,
          store_id: item.store_id,
          store_name: item.store_name,
          created_at: item.created_at,
          price: item.amount,
          count: item.count,
          status: item.status,
          account_no: item.account_no,
        }));
  
        return res;
      },
      getListConfig() {
        return listConfig;
      },
      openModal() {
        this.showModal = true;
      },
      onCloseModal() {
        this.showModal = false;
      },
      async onApplyModal(storeId) {
        this.onCloseModal();
        this.$router.push({
          name: "apply-pay-list",
          params: {
            storeId,
          },
          query:{
            createPageDatetime: dayjs().format("YYYY-MM-DD HH:mm:ss")
          }
        });
      },
    },
  };
  </script>
  
  <style>
  .apply-pay-pager .v-select__selection {
    color: #009775;
  }
  .apply-pay-pager .v-input__slot {
    border: solid 1px #009775;
  }
  .apply-pay-pager .v-text-field__details {
    display: none !important;
  }
  </style>