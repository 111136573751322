import Vue from 'vue'
import {LiffListConfigInterface, TableDataType} from '@/components/list/types/list'
import orderTab from '@/modules/base/config/orderTab.ts';

class listConfig {
    protected vm?: Vue
    protected listKey?: string

    get(vm: Vue, listKey: string): LiffListConfigInterface {
        this.vm = vm
        this.listKey = listKey

        return {
            pageTitle: '請款列表',
            enable: {
                search: true,
            },
            search: '輸入客戶名稱搜尋',
            sort: [
                {label: 'data.created_at', key: 'created_at'},
            ],
            liffFilter: {
                created_at: {
                    label: 'data.created_at',
                    type: 'date',
                },
            },
            perPage: 15,
            perPageOptions: [
                // @ts-ignore
                { text: '每頁顯示15筆', value: 15 },
                // @ts-ignore
                { text: '每頁顯示30筆', value: 30 },
                // @ts-ignore
                { text: '每頁顯示45筆', value: 45 },
                // @ts-ignore
                { text: '每頁顯示60筆', value: 60 }
            ],
            listItem: () => import('@/modules/provider/views/applyPay/item/applyPayItem.vue'),
            useRow: false,
        }

    }
}

export default new listConfig()
